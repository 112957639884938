<template>
  <div class="table-wrapper">
    <div class="cover" v-if="loading"></div>
    <div class="loader" v-if="loading">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <vuetable
      ref="datatable"
      :fields="columns"
      :per-page="per_page"
      :api-url="endpoint"
      data-path="data"
      pagination-path=""
      :http-options="httpOptions"
      :css="cssConfig.table"
      @vuetable:loading="onLoading()"
      @vuetable:loaded="onLoaded()"
      @vuetable:pagination-data="onPaginationData"
    >
      <!-- Date -->
      <div slot="date-slot" slot-scope="props">
        <div class="text-right">
          <span class="align-self-center">{{
            dateChunks(props.rowData.createdAt)[0]
          }}</span>
          <br />
          <span class="align-self-center --text-sm">
            <i class="fal fa-clock"></i>
            {{ dateChunks(props.rowData.createdAt)[1] }}
          </span>
        </div>
      </div>

      <div slot="controls-slot" slot-scope="props">
        <div class="d-flex justify-content-end">
          <router-link
            :to="{ name: 'Company-Update', params: { id: props.rowData.id } }"
            class="btn btn-default btn-sm text-saalari"
          >
            <i class="fal fa-pen"></i>
          </router-link>

          <button
            class="btn btn-default btn-sm text-saalari"
            @click="destroy(props.rowData.id)"
          >
            <i class="fal fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </vuetable>

    <div style="padding-top: 10px">
      <vuetable-pagination
        :css="cssConfig.pagination"
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import { http } from "@/helpers/http";
import { FILTERS } from "@/helpers/filters";

export default {
  name: "CompaniesTable",
  components: { Vuetable, VuetablePagination },
  props: {
    id: {
      type: String,
      required: true,
    },

    columns: {
      type: Array,
      required: true,
    },

    endpoint: {
      type: String,
      required: true,
    },

    custom_class: {
      type: String,
    },

    responsive: {
      type: Boolean,
    },

    per_page: {
      type: Number,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    search_fields: {
      type: Array,
    },

    color_scheme: {
      type: String,
    },
  },

  data() {
    return {
      searchKeyword: null,
      cssConfig: {
        table: {
          tableWrapper: "vuetable-wrapper table-responsive",
          tableHeaderClass: "mb-0",
          tableBodyClass: "mb-0",
          tableClass: "_datatable table table-sm",
          ascendingIcon: "fal fa-sort-amount-up-alt",
          descendingIcon: "fal fa-sort-amount-down-alt",
          ascendingClass: "sorted-asc",
          descendingClass: "sorted-desc",
          sortableIcon: "fal fa-sort-amount-down-alt",
          detailRowClass: "vuetable-detail-row",
          handleIcon: "fa fa-bars text-secondary",
          renderIcon(classes, options) {
            return `<i class="ml-1 ${classes.join(" ")}"></span>`;
          },
        },
        pagination: {
          wrapperClass: "pagination",
          activeClass: "active bg-saalari text-white",
          disabledClass: "disabled",
          pageClass: "btn-nav page-link page-item",
          linkClass: "btn-nav page-link",
          paginationClass: "pagination",
          paginationInfoClass: "float-left",
          dropdownClass: "form-control",
          icons: {
            first: "fa fa-chevron-double-left icon-sm",
            prev: "fa fa-chevron-left icon-sm",
            next: "fa fa-chevron-right icon-sm",
            last: "fa fa-chevron-double-right icon-sm",
          },
        },
      },
      httpOptions: {
        baseURL: http.defaults.baseURL,
        headers: {
          Accept: http.defaults.headers["Accept"],
          "Content-Type": http.defaults.headers["Content-Type"],
          Authorization: http.defaults.headers.common["Authorization"],
        },
      },
      loading: true,
    };
  },

  methods: {
    dateChunks(dateTime) {
      return FILTERS.formatDateTime(dateTime).split(",");
    },

    onLoading() {
      this.loading = true;
    },

    onLoaded() {
      this.loading = false;
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.datatable.changePage(page);
    },

    refreshTable() {
      this.loading = true;
      this.$refs.datatable.refresh();
    },

    edit(company) {
      this.$emit("edit", JSON.parse(JSON.stringify(company)));
    },

    destroy(companyId) {
      this.$emit("destroy", companyId);
    },
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  position: relative;
  min-height: 10rem;

  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    opacity: 0.5;
    z-index: 2;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 5rem;
    color: #574ca5;
  }
}
</style>
