import { http } from "@/helpers/http";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {

        fetchCompanyUpdates({ state, commit, rootState }, payload) {
            http.get(`/api/admin/company-updates?per_page=1&page=${payload.page || 1}&sort_by=updatedAt|desc&searchColumn=companyId&searchKeyword=${payload.companyId}&searchType=eq`).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        toggleCompanyUpdate({ state, commit, rootState }, payload) {
            http.put(`/api/admin/company-updates/${payload.id}/toggle`).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        deleteCompanyUpdate({ state, commit, rootState }, payload) {
            http.delete(`/api/admin/company-updates/${payload.id}`).then(response => {
                if (payload.callback) payload.callback(true);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        }
    }
}