import { http } from "@/helpers/http";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {
        createSubscription({ state, commit, rootState }, payload) {
            http.post("/api/admin/subscriptions", payload.subscription).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        updateSubscription({ state, commit, rootState }, payload) {
            http.put("/api/admin/subscriptions/" + payload.subscriptionId, payload.subscription).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        deleteSubscription({ state, commit, rootState }, payload) {
            http.delete("/api/admin/subscriptions/" + payload.subscriptionId).then(response => {
                if (payload.callback) payload.callback(true);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        }
    }
}