<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { http } from "@/helpers/http";

export default {
  name: "App",

  created() {
    let token = localStorage.getItem("_token");
    if (token) {
      http.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style lang="scss">

html{
  font-size: 14px;
}
</style>
