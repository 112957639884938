<template>
  <div>
    <div class="content container-fluid pt-4">
      <div class="card">
        <div class="card-body">
          <div class="toolbar d-flex justify-content-between mb-4">
            <h5>All Salaries</h5>

            <div class="d-flex align-self-center">
              <button
                class="btn btn-sm btn-outline-saalari mr-2"
                @click="handleDownloadClick()"
              >
                <i class="fal fa-download"></i>
                Download CSV
              </button>

              <button
                class="btn btn-sm btn-outline-saalari align-self-center"
                data-toggle="modal"
                data-target="#salaryModal"
                @click="handleAddNewClick()"
              >
                <i class="fal fa-plus-circle"></i>
                Add New
              </button>
            </div>
          </div>
          <SalariesTable
            id="salaries-table"
            ref="salariesTable"
            :columns="salaryColumns"
            :endpoint="salariesApiEndpoint"
            @destroy="deleteSalary"
            @edit="editSalary"
            @toggleStatus="toggleSalaryStatus"
            @loadSuccess="handleDataLoadSuccess"
          />
        </div>
      </div>
    </div>

    <!-- Salary Form -->
    <div class="modal fade" id="salaryModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="mb-0" v-if="salaryFormMode == 'create'">
              Add new Salary
            </h5>
            <h5 class="mb-0" v-else-if="salaryFormMode == 'edit'">
              Update Salary
            </h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitSalaryForm()">
              <!-- Company Info -->
              <div class="row">
                <div class="col">
                  <div class="form-group mb-3">
                    <label for="companyName">Company name</label>
                    <input
                      type="text"
                      id="companyName"
                      v-model="salaryForm.companyName"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mb-3">
                    <label for="companyName">Select Company</label>

                    <div v-if="companiesLoading">Loading companies...</div>

                    <select
                      v-model="salaryForm.companyId"
                      class="form-control form-control-sm"
                      v-else
                      @change="handleCompanySelected()"
                    >
                      <option value="null" selected="selected">
                        --SELECT---
                      </option>
                      <option
                        v-for="(company, index) in companiesList"
                        :key="index"
                        :value="company.id"
                      >
                        {{ company.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group mb-3">
                    <label for="jobTitle">Job Title</label>
                    <input
                      type="text"
                      id="jobTitle"
                      v-model="salaryForm.jobTitle"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mb-3">
                    <label for="jobFamily">Job Family</label>
                    <select
                      v-model="salaryForm.jobFamily"
                      class="form-control form-control-sm"
                    >
                      <optgroup
                        v-for="(value, key, index) in jobfamilyGroups()"
                        :key="index"
                        :label="value"
                      >
                        <option
                          v-for="(value2, key2) in jobFamilies(null, key)"
                          :key="key2"
                          :value="key2"
                        >
                          {{ value2 }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                </div>
              </div>

              <!-- Salary Info -->
              <div class="row">
                <div class="col">
                  <div class="form-group mb-3">
                    <label for="salaryYearly">Salary Yearly</label>
                    <input
                      type="number"
                      id="salaryYearly"
                      v-model="salaryForm.salaryYearly"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mb-3">
                    <label for="stockGrantYearly">Stock Grant Yearly</label>
                    <input
                      type="text"
                      id="stockGrantYearly"
                      v-model="salaryForm.stockGrantYearly"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group mb-3">
                    <label for="totalYearlyComp"
                      >Total Yearly Compensation</label
                    >
                    <input
                      type="number"
                      id="totalYearlyComp"
                      v-model="salaryForm.totalYearlyComp"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mb-3">
                    <label for="bonusYearlyAvg">Bonus Yearly Average</label>
                    <input
                      type="number"
                      id="bonusYearlyAvg"
                      v-model="salaryForm.bonusYearlyAvg"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <!-- Job Info -->

              <div class="row">
                <div class="col">
                  <div class="form-group mb-3">
                    <label for="totalExp">Total Experience</label>
                    <input
                      type="number"
                      id="totalExp"
                      v-model="salaryForm.totalExp"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mb-3">
                    <label for="expAtCompany">Experience At Company</label>
                    <input
                      type="number"
                      id="expAtCompany"
                      v-model="salaryForm.expAtCompany"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group mb-3">
                    <label for="jobLocation">Job Location</label>
                    <input
                      type="text"
                      id="jobLocation"
                      v-model="salaryForm.jobLocation"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="col">
                  <div class="form-group mb-3">
                    <label for="gender">Gender</label>
                    <select
                      v-model="salaryForm.gender"
                      class="form-control form-control-sm"
                      id="gender"
                    >
                      <option
                        v-for="(value, key) in genders()"
                        :key="key"
                        :value="key"
                      >
                        {{ value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="d-flex justify-content-end p-3 mt-n1">
            <button
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-saalari"
              @click="submitSalaryForm()"
              :disabled="salarySaveLoading"
            >
              <span v-if="salaryFormMode == 'create'">Save</span>
              <span v-else-if="salaryFormMode == 'edit'">Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalariesTable from "@/components/SalariesTable";
import { jobFamilies, levels, jobfamilyGroups, genders } from "@/helpers/enums";
import { http } from "@/helpers/http";

export default {
  name: "Salaries",
  components: { SalariesTable },
  data() {
    return {
      salaryColumns: [
        {
          name: "title-slot",
          title: "Job",
          sortField: "jobTitle",
          dataClass: "v-align-middle",
        },

        {
          name: "company-slot",
          title: "Company",
          sortField: "companyName",
        },

        {
          name: "salaryYearly",
          title: "Salary Yearly",
          sortField: "salaryYearly",
          dataClass: "v-align-middle",
        },

        {
          name: "date-slot",
          sortField: "createdAt",
          title: "Date",
          titleClass: "text-right",
          dataClass: "text-right",
        },

        {
          name: "controls-slot",
          title: "Actions",
          dataClass: "text-right",
          titleClass: "text-right",
          sortField: "status",
        },
      ],

      salariesApiEndpoint: "/api/admin/salaries",
      salaryFormMode: "create", // create, edit

      salaryForm: {
        companyName: null,
        companyLevel: null,
        companyId: null,

        salaryYearly: null,
        stockGrantYearly: null,
        totalYearlyComp: null,
        bonusYearlyAvg: null,

        jobTitle: null,
        jobFamily: null,
        tagFocus: null,
        expAtCompany: null,
        jobLocation: null,
        isRemote: null,

        gender: null,
        race: null,
        highestAcdLevel: null,
        totalExp: null,
        extraDetails: null,
      },

      salarySaveLoading: false,

      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Other", value: 3 },
      ],

      downloadLoading: false,
      companiesLoading: false,
      companiesList: null,
    };
  },

  created() {
    this.fetchCompanies();
  },

  methods: {
    handleDataLoadSuccess(resp) {
      if (resp.headers["content-type"] == "text/csv" && !this.downloadLoading) {
        this.downloadLoading = true;
        this.salariesApiEndpoint = "/api/admin/salaries";
        this.$refs.salariesTable.refreshTable();

        var file = new Blob([resp.data], {
          type: resp.headers["content-type"],
        });

        var a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = "salaries.csv";

        a.click();

        this.salariesApiEndpoint = "/api/admin/salaries";
        setTimeout(() => (this.downloadLoading = false), 1000);
      }
    },

    handleDownloadClick() {
      http.get("/api/admin/salaries?queryType=csv").then((res) => {
        const temp = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "salaries.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    handleCompanySelected() {
      let found = this.companiesList.find(
        (c) => c.id === this.salaryForm.companyId
      );
      if (found && found !== -1) this.salaryForm.companyName = found.name;
    },

    fetchCompanies() {
      this.companiesLoading = true;
      this.$store.dispatch("company/fetchCompanies", {
        callback: (status, data) => {
          if (status) this.companiesList = data.data;
          this.companiesLoading = false;
        },
      });
    },

    handleAddNewClick() {
      this.salaryFormMode = "create";
      this.salaryForm = {};
    },

    editSalary(salary) {
      this.salaryFormMode = "edit";
      this.salaryForm = salary;
      $("#salaryModal").modal("toggle");
    },

    toggleSalaryStatus(salary) {
      this.salaryFormMode = "edit";
      this.salaryForm = salary;
      this.salaryForm.status = !this.salaryForm.status;
      this.submitSalaryForm(() => this.$refs.salariesTable.refreshTable());
    },

    submitSalaryForm(callback) {
      this.salarySaveLoading = true;
      if (this.salaryFormMode == "create") {
        this.$store.dispatch("salary/createSalary", {
          salary: this.salaryForm,
          callback: (status, data) => {
            this.salarySaveLoading = false;
            this.$refs.salariesTable.refreshTable();
            $("#salaryModal").modal("hide");
            if (callback) callback();
          },
        });
      } else if (this.salaryFormMode == "edit") {
        this.$store.dispatch("salary/updateSalary", {
          salaryId: this.salaryForm.id,
          salary: this.salaryForm,
          callback: (status, data) => {
            this.salarySaveLoading = false;
            this.$refs.salariesTable.refreshTable();
            $("#salaryModal").modal("hide");
            if (callback) callback();
          },
        });
      }
    },

    deleteSalary(id) {
      let resp = confirm("Are you sure you want to delete Salary ?");
      if (resp) {
        this.$store.dispatch("salary/deleteSalary", {
          salaryId: id,
          callback: (status, data) => {
            this.$refs.salariesTable.refreshTable();
          },
        });
      } else console.log("No");
    },

    jobFamilies(key, groupKey) {
      return jobFamilies(key, groupKey);
    },

    levels(key, valuesOnly, namesOnly) {
      return levels(key, valuesOnly, namesOnly);
    },

    jobfamilyGroups(key, valuesOnly, namesOnly) {
      return jobfamilyGroups(key, valuesOnly, namesOnly);
    },

    genders(key) {
      return genders(key);
    },
  },
};
</script>