<template>
  <div>
    <div class="content container-fluid pt-4">
      <div class="card">
        <div class="card-body">
          <div class="toolbar d-flex justify-content-between mb-4">
            <h5>All Subscriptions</h5>

            <div class="d-flex align-self-center">
              <button
                class="btn btn-sm btn-outline-saalari mr-2"
                @click="handleDownloadClick()"
              >
                <i class="fal fa-download"></i>
                Download CSV
              </button>

              <button
                class="btn btn-sm btn-outline-saalari align-self-center"
                data-toggle="modal"
                data-target="#subscriptionModal"
                @click="handleAddNewClick()"
              >
                <i class="fal fa-plus-circle"></i>
                Add New
              </button>
            </div>
          </div>
          <CompaniesTable
            id="subscriptions-table"
            ref="subscriptionsTable"
            :columns="subscriptionColumns"
            :endpoint="subscriptionsApiEndpoint"
            @destroy="deleteSubscription"
            @edit="editSubscription"
          />
        </div>
      </div>
    </div>

    <!-- Subscription Form -->
    <div class="modal fade" id="subscriptionModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="mb-0">Add new Subscription</h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitSubscriptionForm()">
              <div class="form-group mb-3">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  v-model="subscriptionForm.email"
                  class="form-control form-control-sm"
                />
              </div>
            </form>
          </div>
          <div class="d-flex justify-content-end p-3 mt-n1">
            <button
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-saalari"
              @click="submitSubscriptionForm()"
              :disabled="subscriptionSaveLoading"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompaniesTable from "@/components/CompaniesTable";
import { FILTERS } from "@/helpers/filters";
import { http } from "@/helpers/http";

export default {
  name: "Manage",
  components: { CompaniesTable },
  data() {
    return {
      subscriptionColumns: [
        {
          name: "email",
          title: "Email",
          sortField: "email",
        },

        {
          name: "emailSent",
          title: "Email sent ?",
          sortField: "emailSent",
          formatter: (val) => (val ? "Yes" : "No"),
        },

        {
          name: "emailCount",
          title: "Count",
          sortField: "emailCount",
        },

        {
          name: "details",
          title: "Details",
          sortField: "details",
        },

        {
          name: "createdAt",
          sortField: "createdAt",
          title: "Date",
          titleClass: "text-right",
          dataClass: "text-right",
          formatter: (value) => {
            return FILTERS.formatDateTime(value);
          },
        },

        {
          name: "controls-slot",
          title: "Actions",
          dataClass: "text-right",
          titleClass: "text-right",
        },
      ],

      subscriptionsApiEndpoint: "/api/admin/subscriptions",
      subscriptionFormMode: "create", // create, edit
      subscriptionForm: {
        email: null,
      },
      subscriptionSaveLoading: false,
    };
  },

  created() {},

  methods: {
    handleAddNewClick() {
      this.subscriptionFormMode = "create";
      this.subscriptionForm = {
        email: null,
      };
    },

    editSubscription(subscription) {
      this.subscriptionFormMode = "edit";
      this.subscriptionForm = subscription;
      $("#subscriptionModal").modal("toggle");
    },

    submitSubscriptionForm() {
      this.subscriptionSaveLoading = true;
      if (this.subscriptionFormMode == "create") {
        this.$store.dispatch("subscription/createSubscription", {
          subscription: this.subscriptionForm,
          callback: (status, data) => {
            this.subscriptionSaveLoading = false;
            this.$refs.subscriptionsTable.refreshTable();
            $("#subscriptionModal").modal("toggle");
          },
        });
      } else if (this.subscriptionFormMode == "edit") {
        this.$store.dispatch("subscription/updateSubscription", {
          subscriptionId: this.subscriptionForm.id,
          subscription: this.subscriptionForm,
          callback: (status, data) => {
            this.subscriptionSaveLoading = false;
            this.$refs.subscriptionsTable.refreshTable();
            $("#subscriptionModal").modal("toggle");
          },
        });
      }
    },

    deleteSubscription(id) {
      let resp = confirm("Are you sure you want to delete Subscription ?");
      if (resp) {
        this.$store.dispatch("subscription/deleteSubscription", {
          subscriptionId: id,
          callback: (status, data) => {
            this.$refs.subscriptionsTable.refreshTable();
          },
        });
      } else console.log("No");
    },

    handleDownloadClick() {
      http.get("/api/admin/subscriptions?queryType=csv").then((res) => {
        const temp = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "subscriptions.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>