export const levels = (key, valuesOnly, namesOnly) => {
    const LEVELS = {
        0: "Junior: 0-3 years of experience",
        1: "Mid-level: 3-7 years of experience",
        2: "Senior: 8- 15 years experience",
        3: "Executive- C-Level role. Most times, years of experience don’t matter."
    }
    if (valuesOnly) return Object.keys(LEVELS);
    if (namesOnly) return Object.values(LEVELS);
    return key ? LEVELS[key] : LEVELS;
}

export const jobFamilies = (key, groupKey) => {
    const JOB_FAMILIES = {
        0: {
            0: "Business Development",
            1: "Sales Development",
            2: "Account Executive",
            3: "BD Manager",
            4: "Account Manager",
            5: "Sales Manager"
        },

        1: {
            6: "Chief Executive Officer",
            7: "Chief Financial Officer",
            8: "Chief Marketing Officer/Marketing Director",
            9: "Chief Operating Officer",
            10: "Chief Technology Officer"
        },

        2: {
            11: "H.R.",
            12: "Office Manager",
            13: "Recruiter",
            14: "Customer Service",
            15: "Operations Manager",
            16: "Finance/Accounting",
            17: "Legal"
        },

        3: {
            18: "Growth Hacker",
            19: "Marketing Manager",
            20: "Content Creator"
        },

        4: {
            21: "Business Analyst",
            22: "Data Scientist",
            23: "Product Manager",
            24: "Project Manager",
            25: "Legal"
        },

        5: {
            26: "Mobile Developer",
            27: "Frontend Developer",
            28: "Backend Developer",
            29: "Full-Stack Developer",
            30: "Engineering Manager",
            31: "QA Engineer",
            32: "DevOps",
            33: "Software Architect"
        },


        6: {
            34: "UI/UX Designer",
            35: "User Researcher",
            36: "Visual Designer",
            37: "Creative Director"
        }
    };

    let valuesToReturn = JOB_FAMILIES;
    if (groupKey) valuesToReturn = valuesToReturn[groupKey];
    if (groupKey && key) valuesToReturn = valuesToReturn[key];
    else if (key) {
        Object.keys(JOB_FAMILIES).some(jfKey => {
            if (typeof valuesToReturn !== "string") valuesToReturn = JOB_FAMILIES[jfKey][key];
        })
    }
    return valuesToReturn;
}

export const jobfamilyGroups = (key, valuesOnly, namesOnly) => {
    const JOB_FAMILY_GROUPS = {
        0: "Sales",
        1: "Management",
        2: "Operations",
        3: "Marketing",
        4: "Other Roles",
        5: "Software Engineering",
        6: "Design"
    }
    if (valuesOnly) return Object.keys(JOB_FAMILY_GROUPS);
    if (namesOnly) return Object.values(JOB_FAMILY_GROUPS);
    return key ? JOB_FAMILY_GROUPS[key] : JOB_FAMILY_GROUPS;
}

export const genders = (key) => {
    const GENDERS = {
        1: "Male",
        2: "Female",
        3: "Other"
    }
    return key ? GENDERS[key] : GENDERS;
}