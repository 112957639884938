import Vue from 'vue'
import moment from 'moment';

export const FILTERS = {
    formatDate: (value) => {
        return value ? moment(value).format("DD MMM YYYY") : '';
    },

    formatDateTime: (value) => {
        return value ? moment(value).format("DD MMM YYYY, hh:mm:ss a") : '';
    },
}

export const registerFilters = function () {
    Object.keys(FILTERS).forEach(key => {
        Vue.filter(key, FILTERS[key]);
    })
}