import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../pages/Login.vue";
import Dashboard from "../pages/Dashboard.vue";
import Salaries from "../pages/Salaries.vue";
import Companies from "../pages/Companies.vue";
import CompanyUpdate from "../pages/Company-Update.vue";
import Subscriptions from "../pages/Subscriptions.vue";

Vue.use(VueRouter);

var routes = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: 'auth' },
    component: Login,
  },
  {
    path: "/",
    name: "Dashboard",
    meta: { layout: 'admin-panel' },
    component: Dashboard,
  },
  {
    path: "/salaries",
    name: "Salaries",
    meta: { layout: 'admin-panel' },
    component: Salaries,
  },
  {
    path: "/companies",
    name: "Companies",
    meta: { layout: 'admin-panel' },
    component: Companies,
  },
  {
    path: "/companies/:id/update",
    name: "Company-Update",
    meta: { layout: 'admin-panel' },
    component: CompanyUpdate,
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    meta: { layout: 'admin-panel' },
    component: Subscriptions,
  }
];

const router = new VueRouter({
  routes,
});

export default router;
