import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from "./router";
import "./styles/main.scss";

Vue.config.productionTip = false

// layouts
import { registerLayouts } from './helpers/layouts';
registerLayouts();

//http
import { setupHttp } from "./helpers/http";
setupHttp();

//http
import { registerFilters } from "./helpers/filters";
registerFilters();

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
