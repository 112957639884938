<template>
  <div>
    <div class="content container-fluid pt-4">
      <div class="card">
        <div class="card-body">
          <div class="toolbar d-flex justify-content-between mb-4">
            <h5>All Companies</h5>

            <div class="d-flex align-self-center">
              <button
                class="btn btn-sm btn-outline-saalari mr-2"
                @click="handleDownloadClick()"
              >
                <i class="fal fa-download"></i>
                Download CSV
              </button>

              <button
                class="btn btn-sm btn-outline-saalari align-self-center"
                data-toggle="modal"
                data-target="#companyModal"
                @click="handleAddNewClick()"
              >
                <i class="fal fa-plus-circle"></i>
                Add New
              </button>
            </div>
          </div>
          <CompaniesTable
            id="companies-table"
            ref="companiesTable"
            :columns="companyColumns"
            :endpoint="companiesApiEndpoint"
            @destroy="deleteCompany"
            @edit="editCompany"
          />
        </div>
      </div>
    </div>

    <!-- Company Form -->
    <div class="modal fade" id="companyModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="mb-0">Add new Company</h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitCompanyForm()">
              <div class="form-group mb-3">
                <div class="row mb-2">
                  <div class="col">
                    <label for="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      v-model="companyForm.name"
                      class="form-control form-control-sm"
                    />
                  </div>

                  <div class="col">
                    <label for="name">Website</label>
                    <input
                      type="text"
                      id="website"
                      v-model="companyForm.website"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col">
                    <label>Company Logo</label>
                    <ImageUpload
                      @input="handlePhotoUpload"
                      :value="companyForm.logoPaths"
                    />
                  </div>

                  <div class="col">
                    <label for="name">Currency</label>
                    <select
                      id="currency"
                      v-model="companyForm.currency"
                      class="form-control form-control-sm"
                    >
                      <option value="0">Naira</option>
                      <option value="1">Dollar</option>
                    </select>

                    <br />

                    <label for="estdAt">Established Year</label>
                    <input
                      type="text"
                      id="estdAt"
                      v-model="companyForm.estdAt"
                      class="form-control form-control-sm"
                    />

                    <br />

                    <label for="employees">No. of employees</label>
                    <input
                      type="text"
                      id="employees"
                      v-model="companyForm.employees"
                      class="form-control form-control-sm"
                      row="10"
                    />
                  </div>
                </div>

                <!-- <div class="row mb-2">
                  <div class="col">
                    <label for="estdAt">Established Year</label>
                    <input
                      type="text"
                      id="estdAt"
                      v-model="companyForm.estdAt"
                      class="form-control form-control-sm"
                    />
                  </div>

                  <div class="col">
                    <label for="employees">No. of employees</label>
                    <input
                      type="text"
                      id="employees"
                      v-model="companyForm.employees"
                      class="form-control form-control-sm"
                      row="10"
                    />
                  </div>
                </div> -->

                <div class="row">
                  <div class="col">
                    <label for="address">Address</label>
                    <textarea
                      id="address"
                      v-model="companyForm.address"
                      class="form-control form-control-sm"
                    />
                    <div class="m-2"></div>
                    <label for="details">Details</label>
                    <textarea
                      id="details"
                      v-model="companyForm.details"
                      class="form-control form-control-sm"
                      rows="20"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="d-flex justify-content-end p-3 mt-n1">
            <button
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-saalari"
              @click="submitCompanyForm()"
              :disabled="companySaveLoading"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompaniesTable from "@/components/CompaniesTable";
import { FILTERS } from "@/helpers/filters";
import ImageUpload from "@/components/ImageUpload";
import { uploadAsset } from "@/helpers/firebase";
import { http } from "@/helpers/http";

export default {
  name: "Manage",
  components: { CompaniesTable, ImageUpload },
  data() {
    return {
      companyColumns: [
        {
          name: "name",
          title: "Name",
          sortField: "name",
          dataClass: "v-align-middle",
        },

        {
          name: "website",
          title: "Website",
          sortField: "website",
          dataClass: "v-align-middle",
          formatter: (value) => {
            if (!value) return "";
            return `<a href="${value}" target="_blank">${value}</a>`;
          },
        },

        {
          name: "estdAt",
          title: "Estd.",
          sortField: "estdAt",
          dataClass: "v-align-middle",
        },

        {
          name: "address",
          title: "Address",
          sortField: "address",
          dataClass: "v-align-middle",
        },

        {
          name: "employees",
          title: "Employees",
          sortField: "employees",
          titleClass: "text-right",
          dataClass: "v-align-middle text-right",
        },

        {
          name: "avgSalary",
          title: "Average Salary",
          sortField: "avgSalary",
          titleClass: "text-right",
          dataClass: "v-align-middle text-right",
        },

        {
          name: "salariesCount",
          title: "Salary Entries",
          sortField: "salariesCount",
          titleClass: "text-right",
          dataClass: "v-align-middle text-right",
        },

        {
          name: "date-slot",
          sortField: "updatedAt",
          title: "Date",
          titleClass: "text-right",
          dataClass: "text-right",
          formatter: (value) => {
            return FILTERS.formatDateTime(value);
          },
        },

        {
          name: "controls-slot",
          title: "Actions",
          dataClass: "text-right v-align-middle",
          titleClass: "text-right",
        },
      ],

      companiesApiEndpoint: "/api/admin/companies",
      companyFormMode: "create", // create, edit
      companyForm: {
        name: null,
      },
      companySaveLoading: false,
    };
  },

  created() {},

  methods: {
    handleAddNewClick() {
      this.companyFormMode = "create";
      this.companyForm = {
        name: null,
        logoPaths: null,
        currency: null,
        details: null,
        website: null,
        employees: null,
        estdAt: null,
        address: null,
      };
    },

    editCompany(company) {
      this.companyFormMode = "edit";
      for (const key in this.companyForm) this.companyForm[key] = company[key];
      $("#companyModal").modal("toggle");
    },

    async handlePhotoUpload(files) {
      console.log(files);
      let photoPaths = "";
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let url;
        if (file instanceof File) {
          try {
            url = await uploadAsset(file);
          } catch (e) {
            console.log(e);
          }
        } else url = file; // file is "null"
        if (photoPaths && photoPaths.length) photoPaths += "|" + url;
        else photoPaths = url;
      }
      this.companyForm.logoPaths = photoPaths;
    },

    submitCompanyForm() {
      this.companySaveLoading = true;
      if (this.companyFormMode == "create") {
        this.$store.dispatch("company/createCompany", {
          company: this.companyForm,
          callback: (status, data) => {
            this.companySaveLoading = false;
            this.$refs.companiesTable.refreshTable();
            $("#companyModal").modal("toggle");
          },
        });
      } else if (this.companyFormMode == "edit") {
        this.$store.dispatch("company/updateCompany", {
          companyId: this.companyForm.id,
          company: this.companyForm,
          callback: (status, data) => {
            this.companySaveLoading = false;
            this.$refs.companiesTable.refreshTable();
            $("#companyModal").modal("toggle");
          },
        });
      }
    },

    deleteCompany(id) {
      let resp = confirm("Are you sure you want to delete Company ?");
      if (resp) {
        this.$store.dispatch("company/deleteCompany", {
          companyId: id,
          callback: (status, data) => {
            this.$refs.companiesTable.refreshTable();
          },
        });
      } else console.log("No");
    },

    handleDownloadClick() {
      http.get("/api/admin/companies?queryType=csv").then((res) => {
        const temp = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "companies.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>