<template>
  <div class="login">
    <div class="auth-box">
      <div class="site-logo">
        <a href="#">
          <span class="font-weight-bold text-saalari">Saalari</span>
        </a>
      </div>
      <div class="auth-box__body card">
        <p class="auth-box-body__msg">Sign in to start your session</p>
        <form @submit.prevent="handleLogin">
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="loginForm.email"
            id="email-textbox"
            placeholder="Email"
            :disabled="loading"
          />

          <div class="mb-2"></div>

          <input
            type="password"
            class="form-control form-control-sm"
            v-model="loginForm.password"
            id="password-textbox"
            placeholder="Password"
            :disabled="loading"
          />

          <div class="auth-box__cta-wrapper d-flex justify-content-center mt-4">
            <button type="submit" class="btn btn-saalari" :disabled="loading">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/helpers/http";

export default {
  name: "Login",
  components: {},

  data() {
    return {
      loginForm: {
        email: "admin@saalari.com",
        password: "password",
      },
      loading: false,
    };
  },

  methods: {
    handleLogin() {
      this.loading = true;
      if (this.loginForm.email && this.loginForm.password) {
        this.$store.dispatch("auth/login", {
          data: this.loginForm,
          callback: (status, data) => {
            if (status) {
              localStorage.setItem("_token", data.token);
              http.defaults.headers.common["Authorization"] = 'Bearer ' + data.token;
              this.$router.push({ name: "Dashboard" });
            } else alert("Login failed");
            this.loading = false;
          },
        });
      }
    },
  },
};
</script>


<style scoped>
</style>