import { http } from "@/helpers/http";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {

        fetchCompanies({ state, commit, rootState }, payload) {
            http.get("/api/admin/companies?per_page=1000&page=1&sort=updatedAt|desc").then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        fetchCompany({ state, commit, rootState }, payload) {
            http.get("/api/admin/companies/" + payload.id).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        createCompany({ state, commit, rootState }, payload) {
            http.post("/api/admin/companies", payload.company).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        updateCompany({ state, commit, rootState }, payload) {
            http.put("/api/admin/companies/" + payload.companyId, payload.company).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        deleteCompany({ state, commit, rootState }, payload) {
            http.delete("/api/admin/companies/" + payload.companyId).then(response => {
                if (payload.callback) payload.callback(true);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        }
    }
}