import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from '@/store/modules/auth';
import DashboardModule from '@/store/modules/dashboard';
import CompanyModule from '@/store/modules/company';
import CompanyUpdateModule from '@/store/modules/companyUpdate';
import SubscriptionModule from '@/store/modules/subscription';
import SalaryModule from '@/store/modules/salary';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: AuthModule,
    dashboard: DashboardModule,
    company: CompanyModule,
    companyUpdate: CompanyUpdateModule,
    salary: SalaryModule,
    subscription: SubscriptionModule,
  }
})
