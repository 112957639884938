import { http } from "@/helpers/http";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {
        fetchDashboardData({ state, commit, rootState }, payload) {
            http.get("/api/admin/dashboard").then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        }
    }
}