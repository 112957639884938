const initFirebase = () => {
    var firebaseConfig = {
        apiKey: "AIzaSyDjejLmr1N6KSH3cnO3C-mppg2Vs1RcyWw",
        authDomain: "accounts-8e233.firebaseapp.com",
        databaseURL: "https://accounts-8e233.firebaseio.com",
        projectId: "accounts-8e233",
        storageBucket: "accounts-8e233.appspot.com",
        messagingSenderId: "670472513933",
        appId: "1:670472513933:web:1ada897fdffb99f45e35c4",
        measurementId: "G-5RB6F4Z3H2"
    };

    if (!window.fbstorage) {
        firebase.initializeApp(firebaseConfig);
        window.fbstorage = firebase.storage().ref("Assets");
    }
}

export const uploadAsset = (file) => {

    return new Promise((resolve, reject) => {
        initFirebase();

        let uploadTask = window.fbstorage.child(file.name).put(file);
        uploadTask.on('state_changed',
            null, // state changed
            (error) => reject(error), // error
            () => {  // success
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    resolve(downloadURL)
                });
            })
    })

}