import { http } from "@/helpers/http";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {
        createSalary({ state, commit, rootState }, payload) {
            http.post("/api/admin/salaries", payload.salary).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        updateSalary({ state, commit, rootState }, payload) {
            http.put("/api/admin/salaries/" + payload.salaryId, payload.salary).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        deleteSalary({ state, commit, rootState }, payload) {
            http.delete("/api/admin/salaries/" + payload.salaryId).then(response => {
                if (payload.callback) payload.callback(true);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        }
    }
}