<template>
  <div class="wrapper">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#">
            <i class="fas fa-bars"></i>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
        </li>

        <li class="nav-item">
          <button class="btn btn-default text-saalari mt-2" @click="logout()">
            <i class="fal fa-sign-out-alt"></i> Sign Out
          </button>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->

      <router-link :to="{ name: 'Dashboard' }" class="brand-link pl-4">
        <span class="brand-text font-weight-bold">Saalari Admin</span>
      </router-link>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              class="nav-item mt-1 mb-1"
              v-for="(menuItem, index) in menus"
              :key="index"
            >
              <router-link
                :to="{ name: menuItem.pathName[0] }"
                class="nav-link"
                :class="{ active: menuItem.pathName.includes(currentRoute) }"
              >
                <i class="nav-icon mr-2" :class="menuItem.icon"></i>
                <p>{{ menuItem.title }}</p>
              </router-link>
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <slot></slot>
    </div>
    <!-- /.content-wrapper -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
</template>

<script>
import { http } from "@/helpers/http";

export default {
  name: "admin-panel",
  components: {},
  data() {
    return {
      currentRoute: null,
      // pathname can contain multiple values
      // routed to the 1st one only
      menus: [
        {
          title: "Dashboard",
          icon: "fal fa-tachometer-fastest",
          pathName: ["Dashboard"],
        },

        {
          title: "Companies",
          icon: "fal fa-building",
          pathName: ["Companies", "Company-Update"],
        },

        {
          title: "Salaries",
          icon: "fal fa-file-invoice-dollar",
          pathName: ["Salaries"],
        },

        {
          title: "Subscriptions",
          icon: "fal fa-envelope-square",
          pathName: ["Subscriptions"],
        },
      ],
    };
  },

  created() {
    this.setCurrentRoute(this.$route);
  },

  methods: {
    setCurrentRoute(route) {
      this.currentRoute = route.name;
    },

    logout() {
      this.$router.push({ name: "Login" });
      localStorage.removeItem("_token");
       http.defaults.headers.common["Authorization"] = null;
    },
  },

  watch: {
    $route(to, from) {
      this.setCurrentRoute(to);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>