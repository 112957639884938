<template>
  <div>
    <div class="content container-fluid pt-4">
      <h5 class="mb-4">Update Company</h5>

      <div v-if="companyLoading"></div>

      <div class="row" v-else>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <form @submit="submitCompanyForm()">
                <div class="row mb-2">
                  <div class="col">
                    <label for="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      v-model="companyForm.name"
                      class="form-control form-control-sm"
                    />
                  </div>

                  <div class="col">
                    <label for="name">Website</label>
                    <input
                      type="text"
                      id="website"
                      v-model="companyForm.website"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col">
                    <label for="estdAt">Established Year</label>
                    <input
                      type="number"
                      id="estdAt"
                      v-model="companyForm.estdAt"
                      class="form-control form-control-sm"
                    />
                  </div>

                  <div class="col">
                    <label for="employees">No. of employees</label>
                    <input
                      type="number"
                      id="employees"
                      v-model="companyForm.employees"
                      class="form-control form-control-sm"
                      row="10"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <label>Company Logo</label>
                    <ImageUpload
                      @input="handlePhotoUpload"
                      :value="companyForm.logoPaths"
                    />
                  </div>

                  <div class="col">
                    <label for="name">Currency</label>
                    <select
                      id="currency"
                      v-model="companyForm.currency"
                      class="form-control form-control-sm"
                    >
                      <option value="0">Naira</option>
                      <option value="1">Dollar</option>
                    </select>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col">
                    <label for="address">Address</label>
                    <textarea
                      id="address"
                      v-model="companyForm.address"
                      class="form-control form-control-sm"
                    />
                    <div class="m-2"></div>
                    <label for="details">Details</label>

                    <vue-editor v-model="companyForm.details"></vue-editor>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-saalari btn-block"
                  :disabled="companySaveLoading"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5>Company Updates from users</h5>
              <div v-if="companyUpdates && companyUpdates.data">
                <div
                  v-for="companyUpdate in companyUpdates.data"
                  :key="companyUpdate.id"
                  class="border p-2 mb-4 rounded"
                >
                  <div class="d-flex justify-content-between mb-2">
                    <p class="lead align-self-center mb-0">
                      <i class="fal fa-user mr-2"></i>
                      {{ companyUpdate.submittedBy }}
                    </p>

                    <div>
                      <span class="align-self-center">{{
                        dateChunks(companyUpdate.createdAt)[0]
                      }}</span>
                      <br />
                      <span class="align-self-center --text-sm">
                        <i class="fal fa-clock"></i>
                        {{ dateChunks(companyUpdate.createdAt)[1] }}
                      </span>
                    </div>
                  </div>

                  <table class="table table-sm">
                    <tr
                      v-for="(value, key, index) in companyUpdate.details"
                      :key="index"
                    >
                      <td class="text-capitalize font-weight-bold">
                        {{ key }}
                      </td>
                      <td><div class="mr-4"></div></td>
                      <td>{{ value }}</td>
                    </tr>
                  </table>
                </div>

                <div class="d-flex justify-content-between mb-2">
                  <p class="mb-0">
                    Showing
                    <span class="font-weight-bold">
                      {{ companyUpdates.current_page }}</span
                    >
                    out of
                    <span class="font-weight-bold">
                      {{ companyUpdates.last_page }}
                    </span>
                  </p>

                  <div>
                    <button
                      v-if="companyUpdates.current_page != 1"
                      class="btn btn-outline-saalari btn-sm text-saalari mr-2"
                      @click="changePage(-1)"
                    >
                      Previous
                    </button>

                    <button
                      v-if="
                        companyUpdates.current_page != companyUpdates.last_page
                      "
                      class="btn btn-outline-saalari btn-sm text-saalari"
                      @click="changePage(1)"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>

              <div v-else class="alert alert-warning">
                No company updates found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FILTERS } from "@/helpers/filters";
import { VueEditor } from "vue2-editor";
import ImageUpload from "@/components/ImageUpload";
import { uploadAsset } from "@/helpers/firebase";

export default {
  name: "Companies-Update",
  components: { VueEditor, ImageUpload },
  data() {
    return {
      companyForm: {
        name: null,
        logoPaths: null,
        details: null,
        website: null,
        employees: null,
        estdAt: null,
        address: null,
        currency: null
      },
      companyUpdates: null,
      companySaveLoading: false,
      companyLoading: false,
      companyUpdateLoading: false,
    };
  },

  created() {
    this.fetchCompany();
    this.fetchUpdates();
  },

  methods: {
    changePage(by) {
      this.companyUpdates.current_page += by;
      this.fetchUpdates();
    },

    dateChunks(dateTime) {
      return FILTERS.formatDateTime(dateTime).split(",");
    },

    fetchCompany() {
      this.companyLoading = true;
      this.$store.dispatch("company/fetchCompany", {
        id: this.$route.params.id,
        callback: (status, data) => {
          if (status)
            for (const key in this.companyForm) {
              if (key == "logoPaths")
                this.companyForm[key] = JSON.parse(data[key]);
              else this.companyForm[key] = data[key];
            }
          this.companyLoading = false;
        },
      });
    },

    fetchUpdates() {
      this.companyUpdateLoading = true;
      this.$store.dispatch("companyUpdate/fetchCompanyUpdates", {
        companyId: this.$route.params.id,
        page: this.companyUpdates ? this.companyUpdates.current_page : 1,
        callback: (status, data) => {
          if (status) this.companyUpdates = data;
          this.companyUpdateLoading = false;
        },
      });
    },

    async handlePhotoUpload(files) {
      console.log(files);
      let photoPaths = "";
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let url;
        if (file instanceof File) {
          try {
            url = await uploadAsset(file);
          } catch (e) {
            console.log(e);
          }
        } else url = file; // file is "null"
        if (photoPaths && photoPaths.length) photoPaths += "|" + url;
        else photoPaths = url;
      }
      this.companyForm.logoPaths = photoPaths;
    },

    submitCompanyForm() {
      this.companySaveLoading = true;
      this.$store.dispatch("company/updateCompany", {
        companyId: this.$route.params.id,
        company: this.companyForm,
        callback: (status, data) => {
          this.companySaveLoading = false;
        },
      });
    },

    deleteCompany(id) {
      let resp = confirm("Are you sure you want to delete Company ?");
      if (resp) {
        this.$store.dispatch("company/deleteCompany", {
          companyId: id,
          callback: (status, data) => {},
        });
      } else console.log("No");
    },

    deleteCompanyUpdate(id) {
      let resp = confirm("Are you sure you want to delete ?");
      if (resp) {
        this.$store.dispatch("companyUpdate/deleteCompanyUpdate", {
          id,
          callback: (status, data) => {},
        });
      } else console.log("No");
    },
  },
};
</script>