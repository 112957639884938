<template>
  <div>
    <div class="content">
      <!-- Stats -->
      <div class="container pt-3" v-if="stats">
        <div class="row">
          <div class="col">
            <div class="info-box">
              <span class="info-box-icon bg-saalari text-white"
                ><i class="fal fa-building"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Companies</span>
                <h3 class="info-box-number">{{ stats.company }}</h3>
              </div>
            </div>
          </div>

           <div class="col">
            <div class="info-box">
              <span class="info-box-icon bg-saalari text-white"
                ><i class="fal fa-user-edit"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Company Updates</span>
                <h3 class="info-box-number">{{ stats.companyUpdate }}</h3>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="info-box">
              <span class="info-box-icon bg-saalari text-white"
                ><i class="fal fa-file-invoice-dollar"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Salaries</span>
                <h3 class="info-box-number">{{ stats.salary }}</h3>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="info-box">
              <span class="info-box-icon bg-saalari text-white"
                ><i class="fal fa-envelope-square"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Subscriptions</span>
                <h3 class="info-box-number">{{ stats.subscription }}</h3>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="container">
        <div class="row">

          <!-- Recent Salaries -->
          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="toolbar d-flex justify-content-between mb-4">
                  <h5>
                    <i class="fal fa-clock mr-2"></i>
                    Recent Salaries
                  </h5>
                </div>
                <SalariesTable
                  id="salaries-table"
                  ref="salariesTable"
                  :columns="salaryColumns"
                  endpoint="/api/admin/salaries?per_page=5"
                />
              </div>
            </div>
          </div>

                    <!-- Recent Salaries -->
          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="toolbar d-flex justify-content-between mb-4">
                  <h5>
                    <i class="fal fa-clock mr-2"></i>
                    Recent Companies
                  </h5>
                </div>
                <CompaniesTable
                  id="companies-table"
                  ref="companiesTable"
                  :columns="companyColumns"
                  endpoint="/api/admin/companies?per_page=5"
                />
              </div>
            </div>
          </div>

          <!-- Top paying Jobs -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalariesTable from "@/components/SalariesTable";
import CompaniesTable from "@/components/CompaniesTable";
import { FILTERS } from "@/helpers/filters";

export default {
  name: "Dashboard",
  components: { SalariesTable, CompaniesTable },
  data() {
    return {
      stats: null,

      salaryColumns: [
        {
          name: "jobTitle",
          title: "Job",
          sortField: "jobTitle",
          dataClass: "v-align-middle"
        },

        {
          name: "company-slot",
          title: "Company",
          sortField: "companyName",
          dataClass: "v-align-middle"

        },

        {
          name: "salaryYearly",
          title: "Salary",
          sortField: "salaryYearly",
          dataClass: "v-align-middle"

        },
      ],

      companyColumns: [
        {
          name: "name",
          title: "Name",
          sortField: "name",
          dataClass: "v-align-middle",
        },

        {
          name: "estdAt",
          title: "Estd.",
          sortField: "estdAt",
          dataClass: "v-align-middle",
        },

        {
          name: "employees",
          title: "Employees",
          sortField: "employees",
          titleClass: "text-right",
          dataClass: "v-align-middle text-right",
        },
      ]
    };
  },

  created() {
    this.$store.dispatch("dashboard/fetchDashboardData", {
      callback: (status, data) => {
        this.stats = data;
      },
    });
  },
};
</script>

<style>
</style>